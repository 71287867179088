
  import userController from '/home/builduser/work/d4e13f6a2ec10edf/packages/checkout-app/src/components/Checkout/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/controllerWrapper.js';

  
const wrapController = null;


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://e9745c9ce468483fb5e92dc23a5a395b@sentry.wixpress.com/3337',
    id: 'e9745c9ce468483fb5e92dc23a5a395b',
    projectName: 'checkout-app',
    teamName: 'jurijm-checkout-test',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/d4e13f6a2ec10edf/packages/checkout-app/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/d4e13f6a2ec10edf/packages/checkout-app/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "jurijm-checkout",
    componentName: "Checkout",
    appDefinitionId: "0aa15524-32c3-4458-924e-20e0ce1da501",
    componentId: "c53db4f8-fc3c-495b-bc33-8c705c6088a1",
    isUsingAppSettings: false,
    projectName: "checkout-app",
    defaultTranslations,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/d4e13f6a2ec10edf/packages/checkout-app/src/components/Checkout/controller.ts",
  };

  export const controller = _controller
  export default controller;
